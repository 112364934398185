const hasArvin = ref(false);
const pageMounted = ref(false);
const observer = ref<MutationObserver>();

export const useJudgeArvin = () => {
  onMounted(() => {
    // Target element ID to watch for
    const targetElementId = "arvinComponent";
    // Create a MutationObserver
    observer.value = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        // Check if nodes were added
        if (mutation.type === "childList") {
          // Check if the added nodes include the target element
          const targetElement = document.getElementById(targetElementId);
          if (targetElement) {
            // Your code to handle the insertion of the target element goes here
            console.log(`Element with ID "${targetElementId}" has been inserted.`);
            hasArvin.value = true;
          } else {
            hasArvin.value = false;
          }
        }
      }
    });

    // Start observing the DOM for changes
    observer.value.observe(document.body, {
      childList: true, // Watch for changes to the child nodes of the body element
      subtree: true    // Include all descendants of the body element
    });

    pageMounted.value = true;
  })

  onBeforeUnmount(() => {
    // Disconnect the observer since we found what we were looking for
    observer.value && observer.value.disconnect();
    pageMounted.value = false;
  })

  return {
    hasArvin,
    pageMounted
  }
}